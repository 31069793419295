<template lang="html">
  <SlideOut
    ref="slide-out-dialog"
    append-to=".videoapp"
    dock="right"
    :visible.sync="visible"
    size="100%"
    mask-color="rgba(0,0,0,0)"
    :title="article.title"
    :close-on-mask-click="false"
    :arrow-button="false"
    custom-class="article-slideout"
    @close="onClose"
  >
    <div slot="header" style="height:50px;">
      <div
        class="vue-slideout-title-text"
        style="height:50px;display: flex;flex-direction: column;justify-content: center;"
      >
        <span>{{ article.title }}</span>
      </div>
      <button
        title="Schließen"
        class="vm-modal__close"
        style="top: 15px; right:30px;"
        @click="uniwebviewClose"
      >
        <span uk-icon="icon: close; ratio: 1.5" class="uk-icon"
          ><svg
            width="30"
            height="30"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            data-svg="close"
          >
            <path
              fill="none"
              stroke="#000"
              stroke-width="1.06"
              d="M16,16 L4,4"
            ></path>
            <path
              fill="none"
              stroke="#000"
              stroke-width="1.06"
              d="M16,4 L4,16"
            ></path></svg
        ></span>
      </button>
    </div>
    <div class="body__title">
      <div class="uk-background-muted uk-padding body">
        <h1
          v-if="article.title"
          v-html="article.title"
          class="body__title"
          id="element"
        ></h1>
        <h3
          v-if="article.subtitle"
          v-html="article.subtitle"
          class="body__subtitle"
        ></h3>
        <div v-if="article.body" class="body_text">
          <vue-markdown>
            {{ article.body }}
          </vue-markdown>
        </div>
      </div>
    </div>

    <Article
      class="uk-background-default articles"
      v-for="(slide, index) in article.slides"
      :key="index"
      :slide="slide"
    ></Article>
  </SlideOut>
</template>

<script>
import SlideOut from "@hyjiacan/vue-slideout";
import "@hyjiacan/vue-slideout/lib/slideout.css";
// import vueCustomScrollbar from "vue-custom-scrollbar";
import { bus } from "../../main";
import Article from "./Article.vue";
export default {
  data: function() {
    return {
      visible: false,
      strings: this._vaData.strings,
      article: Object
    };
  },
  props: {},
  components: {
    SlideOut,
    Article
    // vueCustomScrollbar
  },
  methods: {
    onClose() {},
    uniwebviewClose() {
      window.location.href = "uniwebview://close";
    }
  },
  mounted() {
    bus.$on("show_slideout", data => {
      this.visible = true;
      this.title = data.title;
      this.article = data;
    });
    // bus.$on("hide_slideout", () => {
    //   this.visible = false;
    // });
  }
};
</script>

<style lang="scss" scoped="">
.body__title {
  margin-bottom: 20px;
}
.body__subtitle {
  color: $global-tertiary-color;
  display: block;
  font-size: 1.5em;
  line-height: 1.2;
  margin-top: 0;
}
ul {
  margin-bottom: 10px;
}
.articles {
  padding: 30px;
}
.vue-slideout-header {
  padding: 5px 30px;
}
.body_text {
  margin: 0;
  margin-bottom: 0 !important;
  p {
    margin: 0;
    margin-bottom: 0 !important;
  }
}
</style>
